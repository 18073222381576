<script lang="ts">
	export let showArrowRight = false;
	export let type: 'button' | 'submit' = 'submit';
	export let noLeftRadius = false;
	export let size: 'xl' | 'l' | 'sm' | 'base' = 'sm';
	export let grow = false;
	export let hover = true;
</script>

<button
	{type}
	on:click
	class={(grow ? '' : 'sm:w-auto') +
		'w-full justify-center focus:outline-none text-black bg-accent-primary transition duration-200 font-bold rounded-lg px-5 py-2.5 inline-flex items-center whitespace-nowrap ' +
		(noLeftRadius ? 'rounded-l-none ' : hover ? 'hover:ring-4 hover:ring-accent-primary ' : ' ') +
		(size === 'xl'
			? 'px-6 py-3 text-base'
			: size === 'l'
			? 'px-5 py-3'
			: size === 'base'
			? 'px-5 py-[0.625rem]'
			: 'px-3 py-2 text-sm')}
>
	<slot />
	{#if showArrowRight}
		<svg
			class="ml-2 -mr-1 w-5 h-5"
			fill="currentColor"
			viewBox="0 0 20 20"
			xmlns="http://www.w3.org/2000/svg"
			><path
				fill-rule="evenodd"
				d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
				clip-rule="evenodd"
			/></svg
		>
	{/if}
</button>
